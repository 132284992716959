<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="标题" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['title', { rules: [{ required: true, message: '标题不能为空' }] }]" placeholder="请输入标题"></a-input>
      </a-form-item>
<!--      <a-form-item label="工单类型" :label-col="labelCol" :wrapper-col="wrapperCol">-->
<!--        <a-radio-group @change="ticketTypeChange" v-decorator="['ticketType', { rules: [{ required: true, message: '请选择类型' }] }]" >-->
<!--          <a-radio-button value="INSPECT">巡检</a-radio-button>-->
<!--          <a-radio-button value="REPORT">上报</a-radio-button>-->
<!--          <a-radio-button value="CAR">车辆</a-radio-button>-->
<!--          <a-radio-button value="OTHER">其他</a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-item>-->
      <a-form-item label="描述" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['description',
        { rules: [{ required: true, message: '请输入描述' }] }]" placeholder="请输入描述"></a-input>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="40"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
  import { ticketInfoVO } from './common/ticketInfoVO'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CreateForm',
    components: { QiniuImageUpload },
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: ticketInfoVO()
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
    },
    methods: {
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        /*文件初始化展示处理赋值Ids*/
        values.files.forEach((item) => {
          this.formItem.fileIds.push(item.id)
        })
        this.form.setFieldsValue({
          title: this.formItem.ticketInfo.title,
          ticketType: this.formItem.ticketInfo.ticketType,
          description: this.formItem.ticketInfo.description
        })
      },
      setVOFields (values) {
        this.formItem.ticketInfo.title = values.title
        this.formItem.ticketInfo.description = values.description
      },
      addSubmit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.ticket.info.create,
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = ticketInfoVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('addSuccess')
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          })
      },
      ticketTypeChange (e) {
        this.formItem.ticketInfo.ticketType = e.target.value
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>

<style scoped></style>